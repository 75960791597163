// Development environment configuration
const dev = {
  // API_URL: 'http://192.168.1.136/EMAPROD/Backend' // Local development API URL
  API_URL: 'http://192.168.1.153/EMAPROD/Backend/' // Alternative local development API URL
}

// Production environment configuration
const prod = {
  API_URL: 'https://emaprod.emaransac.com/' // Production API URL
}

// Select the appropriate configuration based on the environment
const config = process.env.NODE_ENV === 'development' ? dev : prod

// Export the selected configuration
export default config